import { render, staticRenderFns } from "./Contacts.vue?vue&type=template&id=7bb04ce6&"
import script from "./Contacts.vue?vue&type=script&lang=js&"
export * from "./Contacts.vue?vue&type=script&lang=js&"
import style0 from "./Contacts.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VHover,VIcon,VList,VListItem,VListItemTitle,VMenu,VSpacer})
