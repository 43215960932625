<template>
  <div>
    <Title :loading="loading">{{ $t("chatSidebar.contacts") }}</Title>
    <div class="chatWrapp">
      <v-expansion-panels flat style="padding-left: 1px">
        <v-expansion-panel class="py-1" v-for="group in contacts.groups" :key="group.id">
          <v-expansion-panel-header class="pl-4" expand-icon="$down">
            <div class="group-name">{{ group.name }}</div>
            <div class="text-right">
            <v-menu bottom left offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <v-icon right class="custom-menu-btn" :color="hover ? 'primary' : 'separator'" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                </v-hover>
              </template>
              <v-list class="py-0">
                <template>
                  <v-list-item link @click="openEditGroupDialog(group)">
                    <v-list-item-title>
                      {{ $t('companies.groups.editName') }}
                    </v-list-item-title>
                  </v-list-item>
                  <v-divider :class="'mx-4'"></v-divider>
                  <v-list-item link @click="remove(group)">
                    <v-list-item-title>
                      {{ $t('companies.groups.delete') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </div>
            <v-divider
              class="mx-4 mr-2 mb-1 custom-vertical-separator"
              inset
              vertical
            ></v-divider>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Contact
              v-for="user in group.users"
              :key="user.userId"
              :contact="user"
              @update="updateContacts"
              @open="startConversationWithUser"
              class="px-2"
            ></Contact>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-expansion-panels flat style="padding-left: 1px">
        <v-expansion-panel class="py-1" v-for="(company, i) in contacts.companies" :key="i">
          <v-expansion-panel-header class="pl-4" expand-icon="$down">
            <div class="d-flex flex-column py-2 mr-4">
              <div class="mb-1"> {{ company.name | trimText(30) }}</div>
              <div class="d-flex">
                <span>{{ company.displayId }}</span>
                <v-spacer></v-spacer>
                <div class="tag d-flex justify-space-between align-center mr-2"><v-icon size="26" color="primary">$vehicles</v-icon>{{ company.vehicles }}</div>
                <div class="tag d-flex justify-space-between align-center mr-2"><v-icon size="20" color="primary">$freights</v-icon>{{ company.freights }}</div>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Contact
              v-for="contact in company.contacts"
              :key="contact.id"
              :contact="contact"
              @update="updateContacts"
              @open="startConversationWithUser"
              class="px-2"
            ></Contact>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <AddGroupDialog ref="addGroupDialog" @save="groupUpdated" />
    <Confirm ref="confirmDialog" />
  </div>
</template>

<script>
import Contact from './Contact.vue'
import Title from '../Shared/Title.vue'
import { startConversation } from '@/signalr/chat'
import AddGroupDialog from '@/chat/Window/Dialogs/AddOrEditChatGroupDialog.vue'
import Confirm from '@/components/dialogs/Confirm.vue'
import Services from '@/services'

export default {
  name: 'Contacts',
  components: { Contact, Title, AddGroupDialog, Confirm },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    contacts () {
      return this.$store.getters.contacts
    }
  },
  methods: {
    groupUpdated () {
      this.$store.dispatch('refreshContacts')
    },
    openEditGroupDialog (group) {
      this.$refs.addGroupDialog.name = group.name
      this.$refs.addGroupDialog.id = group.id
      this.$refs.addGroupDialog.open()
    },
    openDeleteGroupDialog (group) {
      this.$refs.deleteGroupDialog.name = group.name
      this.$refs.deleteGroupDialog.model = { GroupId: group.id }

      this.$refs.deleteGroupDialog.open()
    },
    updateContacts () {
      this.$store.dispatch('refreshContacts')
    },
    startConversationWithUser (userId) {
      startConversation(userId)
    },
    async remove (group) {
      this.loadingDialog = true
      if (await this.$refs.confirmDialog.open(this.$t('deleteDialog.title'), `${this.$t('deleteDialog.label')} ${group.name}?`)) {
        try {
          await Services.delete('Contact/DeleteGroup', {
            groupId: group.id
          })
          this.updateContacts()
        } catch (e) {}
      }
      this.loadingDialog = false
    }
  }
}
</script>
<style>
/* .contact:last-child {
  border-bottom: solid 0.1px rgba(52, 58, 61, 0.1);
} */
.tag {
  width:70px;
  height:26px;
  background-color:var(--v-background-base);
  border-radius:5px;
  padding:0 5px
}
.company {
  color: rgba(52, 58, 61, 0.6);
  font-size: 10px;
}
.chatWrapp .v-expansion-panel-content__wrap {
  padding: 0;
}
.chatWrapp .v-expansion-panel
{
  border: none;
}
.chatWrapp .v-expansion-panel-header__icon span .v-icon__component {
  width: 10px;
  height: 10px;
}
.chatWrapp .v-expansion-panel-header:after {
  content: "";
  border-top: 2px solid var(--v-deal-base);
  bottom: 0px;
  left: 15px;
  width: calc(100% - 30px);
  position: absolute;
}
.custom-vertical-separator{
  position: relative;
  top: -2px;
  right: 3px;
}
.custom-menu-btn{
  max-width: 5px;
}
.group-name{
  word-break: break-all;
}
</style>
